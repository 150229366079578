/* COLORS SHOULD BE IN HEX ONLY */

:root {
    --page_background: #f5f5f5;
    --blocks_background: #ffffff;
    --primary_color: #C75998;
    --darker_background: #ececee;
    --light_grey: #EFF1F3;
    --lighter_grey: #F8F9FB;
  
    --light_text: #ffffff;
    --dark_text: #212353;
  
    --info: #6BD1EB;
    --error: #FE7E7D;
    --warning: #FFBF2F;
    --success: #8CD291;
  
    --links: #4993CC;
    --shadow: 0px .667px 3.148px 0px rgba(73, 101, 148, .02), 0px 2.933px 6.519px 0px rgba(73, 101, 148, .03), 0px 7.2px 13px 0px rgba(73, 101, 148, .04), 0px 13.867px 25.481px 0px rgba(73, 101, 148, .04), 0px 0px 46.852px 0px rgba(73, 101, 148, .05), 0px 0px 80px 0px rgba(73, 101, 148, .07);
    --misc: #A1A1A7;

    --contentDrawerWidth: 400px;
    --current-bullet-scale: 1;
    --current-no-intensity-bullet-scale: 0.5;
    --current-line-scale: 3;
  }
  