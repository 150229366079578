@font-face {
    font-family: 'Poppins';
    src: local('Poppins Regular'), local('Poppins-Regular'),
        url('assets/fonts/Poppins-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins Bold Italic'), local('Poppins-BoldItalic'),
        url('assets/fonts/Poppins-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins ExtraBold Italic'), local('Poppins-ExtraBoldItalic'),
        url('assets/fonts/Poppins-ExtraBoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins Thin'), local('Poppins-Thin'),
        url('assets/fonts/Poppins-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins ExtraBold'), local('Poppins-ExtraBold'),
        url('assets/fonts/Poppins-ExtraBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins Light'), local('Poppins-Light'),
        url('assets/fonts/Poppins-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins Light Italic'), local('Poppins-LightItalic'),
        url('assets/fonts/Poppins-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins ExtraLight'), local('Poppins-ExtraLight'),
        url('assets/fonts/Poppins-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins Bold'), local('Poppins-Bold'),
        url('assets/fonts/Poppins-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins Black'), local('Poppins-Black'),
        url('assets/fonts/Poppins-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins Thin Italic'), local('Poppins-ThinItalic'),
        url('assets/fonts/Poppins-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins Medium Italic'), local('Poppins-MediumItalic'),
        url('assets/fonts/Poppins-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins ExtraLight Italic'), local('Poppins-ExtraLightItalic'),
        url('assets/fonts/Poppins-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins Medium'), local('Poppins-Medium'),
        url('assets/fonts/Poppins-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins SemiBold Italic'), local('Poppins-SemiBoldItalic'),
        url('assets/fonts/Poppins-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins Italic'), local('Poppins-Italic'),
        url('assets/fonts/Poppins-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins Black Italic'), local('Poppins-BlackItalic'),
        url('assets/fonts/Poppins-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins SemiBold'), local('Poppins-SemiBold'),
        url('assets/fonts/Poppins-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

