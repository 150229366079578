@import './fonts.scss';
@import './colors.scss';
@import '../node_modules/quill/dist/quill.snow.css';
@import '../node_modules/quill/dist/quill.bubble.css';
@import '../node_modules/quill/dist/quill.snow.css';
@import 'ngx-toastr/toastr';

html,
body {
    height: 100%;
    width: 100%;
    margin: 0;
    font-family: Poppins, Helvetica, Arial, sans-serif;
    background-color: var(--page_background);
    position: relative;

    .input-container {
        display: flex;
        flex-direction: column;
        gap: 5px;
        position: relative;

        label {
            font-size: 12px;
            font-weight: 400;
            color: var(--dark_text);

            span {
                &.required {
                    color: var(--error);
                }
            }
        }

        hw-icon.input-icon {
            position: absolute;
            bottom: 10px;
            right: 10px;
            pointer-events: none;
            font-size: 16px;
            min-height: 27px;
            display: flex;
            align-items: center;

            &.primary {
                path {
                    fill: var(--primary_color) !important;
                }
            }
        }
    }

    .input:not(ng-dropdown-panel) {
        width: 100%;
        border-radius: 10px;
        border: 1px solid #ccc;
        height: 45px;
        padding: 10px;

        &.ng-invalid.ng-touched {
            border: solid 1px var(--error);
        }

        &.textarea {
            resize: none;
            height: 55px;
        }

        &.hide-icon {
            &::-webkit-calendar-picker-indicator {
                opacity: 0;
            }
        }
    }

    ng-select {
        width: 100%;
        border-radius: 10px;
        border: 1px solid #ccc;
        height: 45px;
        padding: 10px;

        &.ng-select-multiple {
            .ng-select-container {
                .ng-value-container {
                    gap: 10px;

                    .ng-value {
                        color: var(--dark_text);
                        background-color: var(--darker_background);
                        border-radius: 50px;
                        padding: 5px 10px;
                        font-size: 12px;
                        display: flex;
                        flex-direction: row-reverse;
                        gap: 5px;

                        .ng-value-icon.left {
                            background: black;
                            border-radius: 50%;
                            width: 18px;
                            height: 18px;
                            display: flex;
                            justify-content: center;
                            color: white;
                            font-size: 17px;
                            align-items: center;
                        }
                    }
                }
            }
        }

        .ng-select-container {
            .ng-value-container {
                .ng-value {
                    font-size: 12px;
                    color: var(--misc);
                    font-weight: 300;
                    display: flex;
                    align-items: center;
                }
            }
        }
    }

    ng-dropdown-panel {
        z-index: 9999999 !important;
        margin-top: 1px;

        .ng-dropdown-panel-items {
            background: white;
            border-radius: 10px;
            box-shadow: var(--shadow);
            min-width: fit-content;

            .ng-option {
                padding: 5px 10px;
                background: transparent;
                transition: all .15s ease-in-out;

                span.ng-option-label {
                    font-size: 14px;
                    color: var(--misc);
                }

                &:hover, &.ng-option-marked {
                    background: var(--primary_color);
                    transition: all .15s ease-in-out;
                    color: var(--light_text);

                    span.ng-option-label {
                        color: var(--light_text);
                    }
                }
            }
        }
    }
}

* {
    box-sizing: border-box;
    margin: 0;
}

app-root {
    height: 100%;
    display: block;
}

/* START page structure css */

.detail-container {
    display: flex;
    flex-direction: column;
    min-height: 0;
    max-height: -webkit-fill-available;
    height: 100%;

    > .header {
        margin-bottom: 20px;
    }

    > .content {
        flex-grow: 1;
        overflow: auto;
        min-height: 0;
    }
}

/* Quill text editor */

.ql-container {
    height: auto !important;
    
    &.ql-snow {
        border: none !important;
     }

     .ql-editor.ql-blank::before {
        font-style: normal !important;
        left: 0;
    }
    .ql-editor.ql-blank:focus::before {
      content: '';
    }
    .ql-editor {
      padding-left: 0;
    }
}

.ql-toolbar.ql-snow {
    padding: 15px 0 !important;
    
    .ql-formats {
        margin-right: 0 !important;

        .text-format-box {
            gap: 10px;

            button {
                padding: 0 !important;
                height: 18px;
            }
        }
    }
}

/* end Quill text editor */

/* END page structure css */


.cdk-drag-placeholder {
    opacity: 0 !important;
    cursor: grabbing !important;
}

.cdk-drag-preview {
    z-index: 9999999 !important;
    cursor: grabbing !important;

    > div {
        box-shadow: var(--shadow);
    }
}


ngx-slider {
    height: 10px !important;
    margin: 0 !important;

    .ngx-slider-bubble {
        display: none !important;
    }

    .ngx-slider-pointer {
        width: 20px !important;
        height: 20px !important;
        border: solid 2px #ffffff !important;
        background-color: var(--primary_color) !important;
        top: -7px !important;

        &:focus-visible {
            outline: none;
        }

        &.ngx-slider-active:after {
            display: none;
        }

        &:after {
            display: none;
        }
    }

    .ngx-slider-selection-bar {
        visibility: visible !important;

        .ngx-slider-bar {
            background: var(--primary_color) !important;
        }
    }

    .ngx-slider-bar {
        height: 8px !important;
        border-radius: 50px !important;
        background: var(--misc) !important;
    }
}

#toast-container {
    .toast-success {
        background-color: var(--success) !important;
        border-radius: 10px;
    }

    .toast-error {
        background-color: var(--error) !important;
        border-radius: 10px;
    }
}




.check-box .toggle {
    display: none;
}

  .check-box .toggle,
  .check-box .toggle:after,
  .check-box .toggle:before,
  .check-box .toggle *,
  .check-box .toggle *:after,
  .check-box .toggle *:before,
  .check-box .toggle + .toggle-btn {
    box-sizing: border-box;
  }

  .check-box .toggle::-moz-selection,
  .check-box .toggle:after::-moz-selection,
  .check-box .toggle:before::-moz-selection,
  .check-box .toggle *::-moz-selection,
  .check-box .toggle *:after::-moz-selection,
  .check-box .toggle *:before::-moz-selection,
  .check-box .toggle + .toggle-btn::-moz-selection,
  .check-box .toggle::selection,
  .check-box .toggle:after::selection,
  .check-box .toggle:before::selection,
  .check-box .toggle *::selection,
  .check-box .toggle *:after::selection,
  .check-box .toggle *:before::selection,
  .check-box .toggle + .toggle-btn::selection {
    background: none;
  }

  .check-box .toggle + .toggle-btn {
    outline: 0;
    display: block;
    width: 50px;
    height: 25px;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .check-box .toggle + .toggle-btn:after,
  .check-box .toggle + .toggle-btn:before {
    position: relative;
    display: block;
    content: "";
    width: 18px;
    height: 18px;
  }

  .check-box .toggle + .toggle-btn:after {
    left: 0;
  }

  .check-box .toggle + .toggle-btn:before {
    display: none;
  }

  .check-box .toggle:checked + .toggle-btn:after {
    left: 50%;
  }

  .check-box .toggle-ios + .toggle-btn {
    background: transparent;
    border-radius: 2em;
    padding: 2px;
    transition: all 0.4s ease;
    border: 1px solid var(--primary_color);
  }

  .check-box .toggle-ios + .toggle-btn:after {
    border-radius: 2em;
    transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), padding 0.3s ease, margin 0.3s ease;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0 rgba(0, 0, 0, 0.08);
    background: var(--primary_color);
  }

  .check-box .toggle-ios + .toggle-btn:hover:after {
    will-change: padding;
  }

  .check-box .toggle-ios + .toggle-btn:active {
    box-shadow: inset 0 0 0 2em var(--primary_color);
  }

  .check-box .toggle-ios + .toggle-btn:active:after {
    padding-right: 0.8em;
  }

  .check-box .toggle-ios:checked + .toggle-btn {
    background: var(--primary_color);

  }
  .check-box .toggle-ios:checked + .toggle-btn:after {
    background: var(--light_text);
  }

  .check-box .toggle-ios:checked + .toggle-btn:active {
    box-shadow: none;
  }

  .check-box .toggle-ios:checked + .toggle-btn:active:after {
    margin-left: -0.8em;
  }


  .hide-mobile {
    display: block;
  }

  .hide-mobile-flex {
    display: flex;
  }

   .show-mobile, .show-mobile-flex  {
      display: none;
   }

  @media only screen and (max-width: 768px) {
    .hide-mobile, .hide-mobile-flex {
        display: none !important;
    }

    .show-mobile {
        display: block !important;
    }

    .show-mobile-flex {
      display: flex !important;
    }
  }


// ribon css code
  .ribbon {
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: absolute;
    z-index: 1;
  }
//   .ribbon::before,
//   .ribbon::after {
//     position: absolute;
//     z-index: -1;
//     content: '';
//     display: block;
//     border: 5px solid #2980b9;
//   }
  .ribbon span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 15px 0;
    background-color: var(--primary_color);
    box-shadow: 0 5px 10px rgba(0,0,0,.1);
    color: #fff;
    font: 700 18px/1 'Lato', sans-serif;
    text-shadow: 0 1px 1px rgba(0,0,0,.2);
    text-transform: uppercase;
    text-align: center;
  }
  
  /* top left*/
  .ribbon-top-left {
    top: -10px;
    left: -10px;
  }
//   .ribbon-top-left::before,
//   .ribbon-top-left::after {
//     border-top-color: transparent;
//     border-left-color: transparent;
//   }
//   .ribbon-top-left::before {
//     top: 0;
//     right: 0;
//   }
//   .ribbon-top-left::after {
//     bottom: 0;
//     left: 0;
//   }
  .ribbon-top-left span {
    right: -25px;
    top: 30px;
    transform: rotate(-45deg);
  }
  
  /* top right*/
  .ribbon-top-right {
    top: 0px;
    right: 0px;
  }
//   .ribbon-top-right::before,
//   .ribbon-top-right::after {
//     border-top-color: transparent;
//     border-right-color: transparent;
//   }
//   .ribbon-top-right::before {
//     top: 0;
//     left: 0;
//   }
//   .ribbon-top-right::after {
//     bottom: 0;
//     right: 0;
//   }
  .ribbon-top-right span {
    left: -25px;
    top: 30px;
    transform: rotate(45deg);
  }
  
  /* bottom left*/
  .ribbon-bottom-left {
    bottom: -10px;
    left: -10px;
  }
//   .ribbon-bottom-left::before,
//   .ribbon-bottom-left::after {
//     border-bottom-color: transparent;
//     border-left-color: transparent;
//   }
//   .ribbon-bottom-left::before {
//     bottom: 0;
//     right: 0;
//   }
//   .ribbon-bottom-left::after {
//     top: 0;
//     left: 0;
//   }
  .ribbon-bottom-left span {
    right: -25px;
    bottom: 30px;
    transform: rotate(225deg);
  }
  
  /* bottom right*/
  .ribbon-bottom-right {
    bottom: -10px;
    right: -10px;
  }
//   .ribbon-bottom-right::before,
//   .ribbon-bottom-right::after {
//     border-bottom-color: transparent;
//     border-right-color: transparent;
//   }
//   .ribbon-bottom-right::before {
//     bottom: 0;
//     left: 0;
//   }
//   .ribbon-bottom-right::after {
//     top: 0;
//     right: 0;
//   }
  .ribbon-bottom-right span {
    left: -25px;
    bottom: 30px;
    transform: rotate(-225deg);
  }